.topbar-videoroom {
  height: 6%;
  background-color: #303a4c;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 17px;
  font-family: "Lato", sans-serif;
  user-select: none;
}

.end-class {
  cursor: pointer;
  background-color: #ff5e5e;
  border-radius: 6px;
  padding: 0px 10px;
  color: #fff;

  height: 25px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-left {
  width: 75%;
  display: flex;
  align-items: center;

  .topbar-left-div {
    font-size: 14px;
    margin: 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      height: 20px;
      margin: 0;
    }
  }
}

.topbar-mobile {
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.end-class-cont {
position: absolute;
top: 6%;
right: 0;
z-index: 10000;
border-radius: 8px;
background-color: #1b1c23fc;
height: 228px;
width: 284px;
padding: 10px 15px;
display: flex;
flex-direction: column;
justify-content: space-between;

.end-class-text {
color: rgba(255, 255, 255, 0.7);
font-size: 14px;
line-height: 20px;
margin: 10px 0px;
}

.end-class-btn {
border-radius: 6px;
height: 33px;
font-size: 14px;
border: none;
outline: none;
padding: 0px 10px;
margin-bottom: 10px;
cursor: pointer;
}

.leave-class {
background-color: #fff;
color: rgba(116, 129, 158, 0.7);
}

.end-for-all {
background-color: #ff5e5e;
color: #fff;
}
}

//tablet
@media only screen and (min-width: 600px) {
.topbar-videoroom {
height: 10%;
background-color: #303a4c;
}

.topbar-right {
margin: 0px 20px;
}

.topbar-left {
.topbar-left-div {
  margin: 0px 15px;
  img {
    margin: 0;
  }
}
}

.end-class-cont {
top: 10%;
}
}
//laptop
@media only screen and (min-width: 990px) {
.topbar-videoroom {
height: 6vh;
background-color: #080f1e;
}

.topbar-right {
margin: 0px 20px;
}

.audio-only-cont {
display: flex;
align-items: center;
width: 60%;

.audio-only-text {
  font-size: #fff;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 20px;
}
}

.end-class {
font-size: 16px;
height: 30px;
}

.topbar-left {
.topbar-left-div {
  font-size: 15px;
  margin: 0 20px;
  img {
    height: 22px;
    margin: 0px 10px;
  }
}
}

.end-class-cont {
top: 6vh;

.end-class-btn {
  font-size: 16px;
}
}
}

.topbar-right {
width: 25%;
display: flex;
justify-content: flex-end;
align-items: center;
margin-right: 10px;
}

.recording-display {
display: flex;
margin-right: 26px;
background: #1D2331;
padding: 8px 8px 3px 8px;
border-radius: 4px;
.recording-timer{
margin-left: 6px;
margin-top: 1px;
}
}

.share-public-link {
width: 250px;
display: flex;
justify-content: center;
padding: 7px;
border-radius: 4px;
background-color: rgba(70, 79, 101, 0.65);

span {
color: #1da1f2;
}
}

.youtube-progress {
font-size: 14px;
color: rgba(255, 255, 255, 0.6);
display: flex;
align-items: center;

img {
cursor: pointer;
}

.youtube-icon {
border-radius: 4px;
box-shadow: 0 0 0 rgb(255, 94, 94);
animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
0% {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 94, 94, 0.4);
}
70% {
  -webkit-box-shadow: 0 0 0 10px rgba(255, 94, 94, 0);
}
100% {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 94, 94, 0);
}
}
@keyframes pulse {
0% {
  -moz-box-shadow: 0 0 0 0 rgba(255, 94, 94, 0.4);
  box-shadow: 0 0 0 0 rgba(255, 94, 94, 0.4);
}
70% {
  -moz-box-shadow: 0 0 0 10px rgba(255, 94, 94, 0);
  box-shadow: 0 0 0 10px rgba(255, 94, 94, 0);
}
100% {
  -moz-box-shadow: 0 0 0 0 rgba(255, 94, 94, 0);
  box-shadow: 0 0 0 0 rgba(255, 94, 94, 0);
}
}

div {
margin: 0px 5px;
}

.fail {
color: #ff5e5e;
}

.link {
cursor: pointer;
color: #1da1f2;
}
}

progress {
background: #3e4049;
border-radius: 12px;
width: 190px;
height: 12px;
}

progress::-webkit-progress-value {
background: #ff5e5e;
border-radius: 12px;
}

progress::-webkit-progress-bar {
background: #3e4049;
border-radius: 12px;
}