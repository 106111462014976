.fullscreen {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: "Lato", sans-serif;
    -webkit-tap-highlight-color: transparent;
}

.disable-btn {
    cursor: initial !important;
    opacity: 0.4;
}

.enable-btn {
    cursor: pointer !important;
    opacity: 1;
}

.outer-main {
    display: flex;
    height: 94%;
}

.relative {
    position: relative;
}

.pointer {
    cursor: pointer;
}

.flex-center {
    display: flex !important;
    justify-content: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.main {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background-color: #2b3344;
    overflow: hidden;
    display: inline-block;
    position: relative;
}

.main-screen {
    position: relative;
    width: 100%;
    height: calc(100% - 110px);
    background-color: #303a4c;
    display: inline-block;
    overflow: hidden;
    border-radius: 12px;
}

.main-screen-utype3 {
    height: 94vh;
}

.local-video-normal {
    position: absolute;
    width: 100%;
    height: 100%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.local-video-reflect {
    transform: translateY(-50%) scaleX(-1);
}

.current-speaker {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    z-index: 10000;
    display: flex;
    align-items: center;
}

.current-speaker-name {
    background-color: rgba(116, 129, 158, 0.57);
    user-select: none;
    font-size: 12px;
    align-items: center;
    border-radius: 3px;
    padding: 5px 10px;
}

.center-text-main {
    color: #fff;
    font-weight: 400;
    border-radius: 50%;
    text-align: center;
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    user-select: none;
}

.pin-mainscreen {
    cursor: pointer;
    img {
        height: 36px;
    }
}

.current-name {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 15px;
    color: #fff;
    font-size: 18px;
    z-index: 2;
}

.pin-username {
    background-color: rgba(219, 226, 234, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    border-radius: 6px;
    font-size: 16px;
    color: #1f3965;
    height: 32px;
}

.vc-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .vc-loader-gif {
        height: 100px;
    }

    .vc-loader-text {
        margin: 10px 0px;
        font-size: 20px;
        color: #fff;
    }
}

.enter-classroom {
    height: 40px;
    font-size: 14px;
    width: 250px;

    color: #fff;
    font-weight: 700;
    background-color: #1da1f2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: 0px 0px 20px 0px #ffffff66;
    border-radius: 6px;
    outline: none;
    cursor: pointer;
}

.waiting {
    position: absolute;
    height: 94vh;
    width: 100vw;
    z-index: 1000;
    background-color: #303a4c;
    text-align: center;
    div {
        color: #fff;
        background-color: rgba(255, 255, 255, 0.4);
        font-size: 16px;
        padding: 10px 4vw;
    }
}

.go-live-container {
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column-reverse;

    .go-live-sub {
        height: 100%;
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.go-live-controls-container {
    display: flex;
    margin: 0 5vw;
    width: 45%;
    justify-content: space-between;
}

.go-live-video {
    height: 60%;
    width: 300px;
    position: relative;

    .go-live-microphone {
        position: absolute;
        top: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 2;

        div {
            background-color: rgba(103, 131, 149, 0.23);
            color: #fafafa;
            border-radius: 6px;
            padding: 10px;
            font-size: 14px;
        }
    }

    .preview-video {
        height: 100%;
        width: 100%;
        background-color: #051926;
        border-radius: 8px;
        transform: scaleX(-1);
    }
}

.go-live-btn {
    position: absolute;
    bottom: 20px;
    width: 100%;
    button {
        color: #fff;
        background-color: #1da1f2;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 8px;
        outline: none;
        cursor: pointer;
        height: 40px;
        width: 300px;
        font-size: 16px;
    }
    button:hover {
        background-color: #0889d3;
    }
    button:active {
        background-color: #0889d3;
        box-shadow: none;
    }
}

.go-live-classname {
    color: #1f3965;
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0px 20px 0px;
}

.go-live-cover {
    height: 100%;
    width: 100%;
    background-color: #303a4c;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;

    .go-live-box {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .go-live-main {
        color: #fff;
        font-weight: bold;
        width: 88px;
        height: 88px;
        border-radius: 50%;
        line-height: 88px;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
    }
}

.go-live-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    .go-live-button {
        flex: 1;
        color: #fafafa;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        img {
            height: 25px;
            margin-bottom: 3px;
        }
    }
    .button-container {
        background: #080e1d;
        border-radius: 8px;
        width: 61px;
        height: 44px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        margin-right: 20px;

        &.lean {
            width: 44px;
        }
        .button-container-sub {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .recording-indicator-container {
            display: flex;
            margin-left: -15px;
            cursor: default;
            &.mobile {
                margin-left: -20px;
            }
            .recording-indicator-anim {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #ff5e5e;
                animation-name: recordingAnim;
                animation-duration: 2s;
                animation-iteration-count: infinite;
            }
        }
    }
}

.go-live-recording {
    color: #d35757;
    font-size: 16px;
    margin: 10px 0px 20px 0px;
}

.poll-timer {
    height: 30px;
    width: 64px;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    background-color: #ff5e5e;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
}

.toast-message {
    color: #fff;
    background-color: rgba(29, 161, 242, 0.7);
    font-size: 12px;
    border-radius: 25px;
    padding: 10px 10%;
    text-align: center;
}

.vc-top-right {
    position: absolute;
    top: 0px;
    right: 5px;
    z-index: 28;

    .row {
        display: flex;
        justify-content: flex-end;

        .row-child {
            cursor: pointer;
            margin-left: 10px;
        }
    }

    .row:first-child {
        min-height: 40px;
    }
}

::-webkit-scrollbar {
    display: none;
}

.mb-chat-btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(8, 14, 29, 0.7);
    font-size: 13px;
    color: #fff;
    border: 0.6px solid rgba(255, 255, 255, 0.22);
    height: 30px;
    border-radius: 4px;
    z-index: 31;

    .mb-chat-inner {
        position: relative;
        display: flex;
        padding: 5px 8px;
    }

    span {
        height: 20px;
        width: 20px;
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        background-color: #ff5e5e;
        color: #fff;
        font-size: 10px;
    }

    img {
        height: 20px;
    }
}

.stop-poll-btn {
    position: absolute;
    left: 10px;
    bottom: 10px;
    background-color: #ff5e5e;
    height: 26px;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    z-index: 31;
}

.poll-answered-count {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #ff9b14;
    font-size: 12px;
    z-index: 31;
}

.retry-btn {
    margin-top: 6vh;
    font-size: 1.2rem;
    padding: 10px 20px;
    background: #1da1f2;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    min-width: 150px;
    text-align: center;
}

.image-endclass {
    max-width: 95vw;
}

.heading-endclass {
    font-size: 1.5rem;
    color: #1f3965;
}

.recording-animation {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    width: 20%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.recording-anim {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff5e5e;
    animation-name: recordingAnim;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes recordingAnim {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.recording-animation-txt {
    color: #ff5e5e;
    font-weight: bold;
    font-size: 14px;
    margin-left: 5px;
}

.switch-camera {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 10px;
}

//tablet
@media only screen and (min-width: 600px) {
    .fullscreen {
        height: 100%;
        -webkit-tap-highlight-color: transparent;
    }

    .outer-main {
        height: 90%;
    }

    .main {
        background-color: #2b3344;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .main-screen {
        height: 100%;
        width: calc(100% - 118px);
    }

    .main-screen-utype3 {
        width: 100%;
    }

    .local-video-normal {
        height: 100%;
        width: 100%;
    }

    .current-speaker {
        top: 10px;
        left: 10px;
    }

    .current-speaker-name {
        font-size: 12px;
    }

    .center-text-main {
        width: 100px;
        height: 100px;
        line-height: 100px;
        font-size: 30px;
    }

    .pin-mainscreen {
        img {
            height: 36px;
        }
    }

    .vc-loader {
        .vc-loader-gif {
            height: 100px;
        }

        .vc-loader-text {
            margin: 10px 0px;
            font-size: 20px;
        }
    }

    .enter-classroom {
        height: 40px;
        font-size: 14px;
        width: 250px;
        border-radius: 6px;
    }

    .waiting {
        div {
            font-size: 16px;
            padding: 10px 4vw;
        }
    }

    .go-live-container {
        height: 100%;
        width: 100%;
        flex-direction: row;

        .go-live-sub {
            flex: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .go-live-video {
        height: 85%;
        width: 100%;

        .go-live-microphone {
            div {
                padding: 6px;
                font-size: 13px;
            }
        }

        .go-live-bottom {
            height: 20%;

            .go-live-button {
                flex: none;
                width: 25%;
                font-size: 12px;
                img {
                    height: 20px;
                }
            }
        }
    }

    .go-live-recording {
        margin: 5vh;
    }

    .mb-chat-btn {
        position: absolute;
        bottom: 15px;
        left: 10px;
    }

    .retry-btn {
        font-size: 1.2rem;
    }
}
//laptop
@media only screen and (min-width: 990px) {
    .fullscreen {
        height: 100vh;
    }

    .outer-main {
        height: initial;
    }

    .main {
        width: 100vw;
        background-color: #080f1e;
        height: 94vh;
        position: relative;
    }

    .teacher-sideview:hover .teacher-action-cont {
        visibility: visible;

        div {
            cursor: pointer;
        }
    }

    .main-screen {
        height: calc(94vh - 110px);
        width: 100%;
    }

    .main-screen-no-student {
        height: 94vh;
    }

    .main-screen-shrink {
        border-right: 6px solid #080f1e;
        width: 80vw;
    }

    .main-fullscreen {
        height: 100vh;
    }

    .main-screen-utype3 {
        height: 94vh;
    }

    .local-video-normal {
        width: 100%;
        height: 100%;
    }

    .local-video-no-student {
        height: 100%;
    }

    .current-speaker {
        top: auto;
        bottom: 9vh;
        left: 30px;
    }

    .current-speaker-name {
        font-size: 13px;
        // margin-bottom: -10px;
    }

    .center-text-main {
        width: 120px;
        height: 120px;
        line-height: 120px;
        font-size: 42px;
    }

    .pin-mainscreen {
        img {
            height: inherit;
        }
    }

    .vc-loader {
        .vc-loader-gif {
            height: inherit;
        }

        .vc-loader-text {
            margin: 30px 0px;
            font-size: 30px;
        }
    }

    .enter-classroom {
        height: 60px;
        width: 430px;
        font-size: 18px;
        border-radius: 9px;
    }

    .waiting {
        div {
            font-size: 27px;
            padding: 10px 4vw;
        }
    }

    .go-live-container {
        height: 50vh;
        width: 90vw;
        margin: 10vh 5vw 0vh 5vw;
        padding: 0px;
        flex-direction: row;

        .go-live-sub {
            flex: 1;
            display: block;
        }
    }

    .go-live-video {
        height: 100%;
        width: 100%;

        .go-live-microphone {
            div {
                padding: 10px;
                font-size: 16px;
            }
        }

        .go-live-bottom {
            .go-live-button {
                flex: none;
                width: 13vh !important;
                font-size: 14px;
                img {
                    height: 30px;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .go-live-btn {
        bottom: 0px;
        button {
            height: 40px;
            width: 300px;
            font-size: 16px;
        }
    }

    .go-live-classname {
        font-size: 22px;
        margin-top: 3vh;
    }

    .poll-timer {
        height: 32px;
        width: 60px;
        font-size: 16px;
    }

    .toast-message {
        font-size: 1.2rem;
    }

    .vc-top-right {
        z-index: 31;
        top: 10px;
        right: 10px;

        .row:first-child {
            min-height: 40px;
        }
    }

    ::-webkit-scrollbar {
        width: 0.2vw;
        cursor: pointer;
        display: initial;
    }

    ::-webkit-scrollbar-thumb {
        background: #1da1f2;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #d3d3d3;
        border-radius: 4px;
    }

    .retry-btn {
        font-size: 1.5rem;
    }

    .recording-animation {
        width: initial;
        left: initial;
        top: 0px;
    }

    .recording-anim {
        position: absolute;
        right: 0;
        margin-right: 1.35rem;
        width: 13px;
        height: 13px;
    }
}

.center-text-box-main {
    position: absolute;
    top: 50%;
    left: 50%;
    text-transform: "capitalize";
    transform: translate(-50%, -50%);
}

.sidescreen {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 20vw;
    z-index: 32;
}

.sidescreen-hide {
    height: 7vh;
}

.sidescreen-inner {
    height: calc(100% - 7vh);
}

.file-upload-input {
    opacity: 0;

    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

    /* IE 5-7 */
    filter: alpha(opacity=0);

    /* Netscape or and older firefox browsers */
    -moz-opacity: 0;

    /* older Safari browsers */
    -khtml-opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.toast-container-videoroom {
    position: absolute;
    top: 6vh;
    width: 100%;
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.screen-share {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #2a3243;
    z-index: 2;
}

.screen-share-content {
    height: 50%;
    width: 60%;
    position: absolute;
    top: 57%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: 2a3243;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    font-size: 24px;
}

.screen-share-content div {
    color: #fff;
    font-family: "Lato", sans-serif;
}

.stop-presenting {
    background-color: #526a93;
    color: #fff;
    width: 230px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 30px;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
}

.mute-all-students {
    background-color: #080f1e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 110px;
    width: 120px;

    button {
        height: 90px;
        width: 90px;
        flex-direction: column;
        color: #b6bdcc;
        background-color: #303a4c;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        box-shadow: 0 0 12px #f6f5ff33;
        border-radius: 8px;
        outline: none;
        cursor: pointer;

        img {
            margin-bottom: 10px;
        }
    }
    button:hover {
        background-color: rgba(255, 94, 94, 0.43);
    }
    button:active {
        background-color: rgba(255, 94, 94, 0.5);
        box-shadow: none;
        transform: scaleX(0.97) scaleY(0.98);
    }
}

.video-reflect {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
}

.class-details-videoroom {
    position: absolute;
    display: inline-block;
    padding: 15px;
    font-size: 15px;
    top: 6vh;
    left: 5vw;
    z-index: 10000;
    background-color: #1c1d23;
    color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    div {
        margin: 10px 0px;
    }

    .safe-secure {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #57f4b333;
        border-radius: 8px;
        border: 1px solid #47eda8;
        padding: 0 10px;
        margin-top: 20px;
        height: 35px;
    }
}

.go-live-header {
    height: 8vh;
    color: #1f3965;
    font-size: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(162, 161, 161, 0.23);
    padding: 0 5vw;

    img {
        height: 41px;
        width: 41px;
        object-fit: cover;
        border-radius: 50%;
        margin-right: 25px;
    }
}

.recording {
    position: absolute;
    right: 10px;
    width: 100px;
}

.picture-in-picture {
    position: absolute;
    z-index: 22;
    left: 10px;
    top: 10px;

    img {
        height: 30px;
        cursor: pointer;
    }
}

.audio-wave {
    width: 25px;
}

.audio-form {
    display: block;
    border-radius: 5px;
    bottom: 0px;
    width: 5px;
    height: 5px;
    background: #1da1f2;
    position: absolute;
    animation: audio-wave 1s infinite ease-in-out;
}

.audio-form:nth-child(2) {
    left: 7px;
    animation-delay: 0.2s;
}

.audio-form:nth-child(3) {
    left: 14px;
    animation-delay: 0.4s;
}

@keyframes audio-wave {
    0% {
        height: 5px;
        transform: translateY(0px);
    }
    25% {
        height: 25px;
        transform: translateY(10px);
    }
    50% {
        height: 5px;
        transform: translateY(0px);
    }
    100% {
        height: 5px;
        transform: translateY(0px);
    }
}

.student-video-container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.video-device {
    font-size: 12px;
    cursor: pointer;
    color: #1f3965;
    line-height: 30px;
    padding: 0px 10px;
    user-select: none;
}

.video-device:last-child {
    border: none;
}

.video-device-selected {
    font-size: 12px;
    cursor: pointer;
    color: #1f3965;
    background-color: #f2f4f8;
    border: 1px solid #dbe2ea;
    line-height: 40px;
    padding: 0px 10px;
    user-select: none;
    border-radius: 6px;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    width: 93%;
    img {
        margin: 0px 5px;
    }
}

.device-type {
    color: rgba(81, 98, 137, 0.72);
    font-size: 12px;
    line-height: 11px;
    background-color: #fff;
    margin-left: 10px;
    margin-bottom: 5px;
}

.device-selected-text {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.device-container {
    width: 93%;
    position: absolute;
    z-index: 2;
    background-color: #fff;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 3px 5px rgba(157, 182, 217, 0.2);
    border: 1px solid #dbe2ea;
    margin-left: 10px;
    margin-right: 10px;
}

.device-sub {
    width: 47%;
    position: relative;
    margin-top: 20px;
}

#orange-bubble {
    background-color: #ea8804;
    position: absolute;
    z-index: 32;
    border-radius: 50%;
    height: 350px;
    width: 350px;
    top: -75px;
    right: -50px;
    display: none;
}

#bubble-text {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 32;
    width: 200px;
    display: none;

    .header {
        color: #fff;
        font-size: 18px;
        padding: 10px 0px;
    }

    .text {
        color: #fff;
        font-size: 14px;
        padding: 10px 0px;
    }

    .lock-btn {
        background-color: #1da1f2;
        color: #fff;
        font-size: 16px;
        height: 42px;
        width: 129px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;
    }
}

#white-bubble {
    display: none;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 32;
    padding: 10px;

    img {
        cursor: pointer;
    }
}

#vc_dummy_canvas {
    display: none;
}

input {
    width: 100%;
    border: 0;
    outline: 0;
}

.display-none {
    display: none;
}

.video-options-panel {
    position: fixed;
    flex-direction: column !important;
    z-index: 10;
    bottom: 8vh;
    padding-top: 10px;
    width: 128px;
    font-size: 14px;
    background-color: #1d1e24;
    border-radius: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    font-family: "Lato", sans-serif;
    text-align: center;
    color: #fafafa;
    div {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
    }
    .video-option {
        cursor: pointer;
        padding: 10px 20px;
    }
    .selected {
        color: #fff;
        background-color: #414348;
        border-radius: 7px;
    }
}
