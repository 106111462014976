.timer_mobile {
    background: inherit;
    display: contents;
}

.timer_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
}

.timer_text {
    flex-grow: 1;
}
