.vc-modal-container {
    height: 100vh;
    width: 100vw;
    z-index: 100000000;
    background-color: rgba(60, 68, 88, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
}
  
  .vc-modal-content {
    position: relative;
    flex-flow: column nowrap;
    padding: 15px;
    width: 330px;
    background-color: #ffffff;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Lato;
    &.wide{
      width: 450px;
    }
  }
  
  .vc-modal-content .header {
    margin: auto;
    margin-bottom: 5px;
    flex-flow: column nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1F3965;
  }
  
  .vc-modal-content .main-content {
    margin: 10px auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    min-height: 30px;
    color: #6B82AB;

    .modal-options{
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      .modal-opt{
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .cross-btn{
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
  
  .vc-modal-btn-area {
    display: flex;
  }
  
  .vc-modal-content .btn {
    flex: 1;
    height: 40px;
    padding: 0px 16px;
    margin: 5px 5px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background: #1da1f2;
    color: #ffffff;
    border-radius: 6px;
    border: none;
  }
  
  .cancel-btn {
    background-color: transparent;
    color: #6b82ab;
    border-radius: 6px;
    border: 1px solid #6b82ab;
  }
  
  .modal-close {
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;
    &:hover {
      color: black;
    }
  }  
