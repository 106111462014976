.sys-stats-container {
    height: 100vh;
    width: 100vw;
    z-index: 999;
    background-color: rgba(60, 68, 88, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
}
  
  .sys-stats-mainBox {

    position: relative;
    flex-flow: column nowrap;
    // padding: 15px;
    width: 498px;
    min-height: 370px;
    background-color: #ffffff;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Lato;
    font-weight: 600;
    &.wide{
      width: 450px;
    }
  }
  
  .sys-stats-mainBox .header {
    margin: auto;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    padding: 10px;
    border-bottom: 2px solid #DBE2EA;
    font-size: 16px;
    line-height: 19px;
    color: #1F3965;
    .cross-btn{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
  }

  .sys-stat-content{
      height: auto;
      padding: 12px;
      .sec-1{
          height: 60px;
          width: 100%;
          display: flex;
          margin: 0;
          p{
              margin: 0;
          }
          .sec-1-icon{
              min-width:20px;

          }
          .sec-1-txt{
              margin-left: 16px;

              .sec-1-txt-title{
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0em;
              }
              .sec-1-txt-info{
                margin-top: 9px;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0em;
                color: #6B82AB;
              }
          }
        //   background-color: green;
      }
      .sec-2{
          min-height: 79px;
          background-color: #f8f9fb;
        //   opacity: 20%;
          border-radius: 8px;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          .stat-info-box{
              margin: 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 14px;
              flex-direction: column;
              p{
                margin: 0;
              }
              .stat-info-key{
                font-size: 14px;
                margin-top: 4px;
                color: #6B82AB;
              }

          }

      }
      .sec-3{
          border-radius: 8px;
          margin-top: 12px;
          padding: 10px;
          ul{
              margin-left: 35px;
          }
          li{
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 4px;
            color: #1F3965;            
          }
          background-color: #eff9ff;
          .sec-3-title{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-left: 6px;
            p{
                margin: 0;
            }
            .title{
                font-size: 14px;
                line-height: 17px;
                letter-spacing: 0em;
                color: #1DA1F2;
                margin-left: 8px;
            }
          }
      }
  }
  
  .vc-modal-content .main-content {
    margin: 10px auto;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    min-height: 30px;
    color: #6B82AB;

    .modal-options{
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      .modal-opt{
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    
  }
  
  .vc-modal-btn-area {
    display: flex;
  }
  
  .vc-modal-content .btn {
    flex: 1;
    height: 40px;
    padding: 0px 16px;
    margin: 5px 5px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background: #1da1f2;
    color: #ffffff;
    border-radius: 6px;
    border: none;
  }
  
  .cancel-btn {
    background-color: transparent;
    color: #6b82ab;
    border-radius: 6px;
    border: 1px solid #6b82ab;
  }
  
  .modal-close {
    color: #aaa;
    line-height: 50px;
    font-size: 80%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;
    &:hover {
      color: black;
    }
  }
  @media only screen and (max-width: 600px){
      .sys-stats-mainBox{
          height: 445px;

      }

  }  
