:root {
    --tooltip-text-color: white;
    --tooltip-background-color: rgba(8, 14, 29, 0.71);
    --tooltip-margin: 35px;
}
  
.tooltip-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}
  
.tooltip-cont {
    position: absolute;
    border-radius: 8px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px 12px;
    color: var(--tooltip-text-color);
    background: var(--tooltip-background-color);
    font-size: 12px;
    z-index: 100;
    white-space: nowrap;
}

.tooltip-cont.move-right {
    left: 0%;
    transform: translateX(-10%);
}

.tooltip-cont.move-left {
    left: 100%;
    transform: translateX(-90%);
}
  
.tooltip-cont.top {
    top: calc(var(--tooltip-margin) * -1 - 3px);
}
  
.tooltip-cont.right {
    left: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}
  
.tooltip-cont.bottom {
    bottom: calc(var(--tooltip-margin) * -1);
}
  
.tooltip-cont.left {
    left: auto;
    right: calc(100% + var(--tooltip-margin));
    top: 50%;
    transform: translateX(0) translateY(-50%);
}