.vc-warning-container {
  height: 100%;
  width: 100%;
  z-index: 100000000;
  background-color: rgba(60, 68, 88, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .vc-warning-modal {
    max-width: 90%;
    background-color: #1b1c23;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    border-radius: 8px;
  }

  .vc-warning-header {
    font-size: 20px;
    color: #fff;
    margin-bottom: 30px;
    text-align: center;
  }

  .vc-warning-title {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .vc-warning-cont {
    width: 100%;
    button {
      border: none;
      cursor: pointer;
      height: 32px;
      width: 45%;
      border-radius: 6px;
      font-size: 16px;
      color: #ffffff;
    }
    button:last-child {
      margin-left: 10%;
    }

    .vc-left-btn {
      background-color: #ff5e5e;
    }

    .vc-right-btn {
      background-color: #5db082;
    }
  }
}

//tablet
@media only screen and (min-width: 600px) {
  .vc-warning-container {
    .vc-warning-modal {
      max-width: 50%;
    }
    .vc-warning-cont {
      width: 100%;
      
      button {
        height: 32px;
        width: 45%;
      }
    }
  }
}

//laptop
@media only screen and (min-width: 990px) {
  .vc-warning-container {
    height: 100vh;
    width: 100vw;
    .vc-warning-modal {
      max-width: 450px;
    }
    .vc-warning-cont {
      button {
        height: 44px;
        min-width: 170px;
      }
      button:last-child {
        margin-left: 30px;
      }
    }
    .vc-warning-icon {
      margin-bottom: 15px;
    }

    .vc-warning-cross {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
